import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import axios from "axios";
import { apiUrl } from "./urls";
import authProvider from "./authProvider";

const httpClient = fetchUtils.fetchJson;

axios.defaults.withCredentials = true;

const DataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: { [field]: order === "ASC" ? 1 : -1 },
      range: { start: (page - 1) * perPage, count: perPage },
      filter: params.filter,
    };

    const url = `${apiUrl}/${resource}`;
    console.log(`getList url: ${url}`);
    //console.log(`getList query: ${JSON.stringify(query)}`);

    const response = await axios.get(url, {
      params: query,
      headers: authProvider.authHeader(),
    });

    const data = response.data.data.map((resource) => ({
      ...resource,
      id: resource._id,
    }));
    console.log(JSON.stringify(data));
    return {
      data: data,
      total: response.data.total,
    };
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    console.log(`getOne url: ${url}`);
    const response = await axios.get(url, {
      headers: authProvider.authHeader(),
    });
    console.log(`getOne: ${JSON.stringify(response.data)}`);

    if (response.data) {
      return {
        data: Object.assign({ id: response.data._id }, response.data),
      };
    } else {
      return { data: null };
    }
  },

  create: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    console.log(`create url: ${url}`);
    console.log("create params: ", params);
    const formData = new FormData();
    const keys = Object.keys(params.data);
    keys.forEach((akey) => {
      let o = params.data[akey];
      if (o) {
        if (o.rawFile) {
          formData.append(akey, o.rawFile);
        } else {
          formData.append(akey, o);
        }
      }
    });

    const response = await axios.post(url, formData, {
      headers: {
        ...authProvider.authHeader(),
        "content-type": "multipart/form-data",
      },
    });
    return {
      data: Object.assign({ id: response.data._id }, response.data),
    };

    // return httpClient(url, {
    //     method: 'POST',
    //     body: JSON.stringify(params.data),
    //     headers: authProvider.authHeader()
    // }).then(({ json }) => ({
    //     data: { ...params.data, id: json.id },
    // }));
  },

  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    console.log(`update url: ${url}`);
    console.log("update params: ", params);
    const formData = new FormData();
    buildFormData(formData, params.data);

    const response = await axios.patch(url, formData, {
      headers: {
        ...authProvider.authHeader(),
        "content-type": "multipart/form-data",
      },
    });
    return {
      data: Object.assign({ id: response.data._id }, response.data),
    };
  },

  delete: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    console.log(`delete url: ${url}`);
    const response = await axios.delete(url, {
      headers: authProvider.authHeader(),
    });
    console.log(`delete response: ${JSON.stringify(response)}`);
    return {
      data: Object.assign({ id: response.data.data._id }, response.data.data),
    };
  },

  getMany: async (resource, params) => {
    console.log(`getMany resource: ${resource}`);
    const query = {
      filter: { ids: params.ids },
    };
    const url = `${apiUrl}/${resource}`;

    console.log(`getMany url: ${url}`);
    console.log(`getMany query: ${JSON.stringify(query)}`);
    const response = await axios.get(url, {
      params: query,
      headers: authProvider.authHeader(),
    });
    console.log(`getMany response: ${JSON.stringify(response)}`);

    return {
      data: response.data.data.map((resource) => ({
        ...resource,
        id: resource._id,
      })),
      total: response.data.total,
    };
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: { ids: params.ids },
    };
    const url = `${apiUrl}/${resource}`;
    console.log(`deleteMany url: ${url}`);

    const response = await axios.delete(url, {
      params: query,
      headers: authProvider.authHeader(),
    });

    console.log(`deleteMany response: ${JSON.stringify(response)}`);
    return { data: [] };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    console.log(`getManyReference url: ${url}`);
    return httpClient(url, {
      headers: authProvider.authHeader(),
    }).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    console.log(`updateMany url: ${url}`);
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify(params.data),
      headers: authProvider.authHeader(),
    }).then(({ json }) => ({ data: json }));
  },
};

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}.${key}` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export default DataProvider;
