import * as React from 'react';
import { List, Datagrid, TextField, Show, SimpleShowLayout, 
  Filter, TextInput, ReferenceField } from 'react-admin';

const MemFilter = props => (
  <Filter {...props}>
    <TextInput label="Search uid" source="uid" alwaysOn />
    <TextInput source="id" />
    <TextInput source="krid" />
    <TextInput source="kbase" />
  </Filter>
);

export const MemList = props => (
  <List filters={<MemFilter />} {...props} sort={{ field: 'ts', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="_id" />
      <TextField source="kbase" />
      <ReferenceField label="User" source="uid" reference="users">
        <TextField  source="email" />
      </ReferenceField>      
      <TextField source="krid" />
      <TextField source="level" />
      <TextField source="df" />
      <TextField source="nrt" />
      <TextField source="failed" />
      <TextField source="ts" />
      <TextField source="ts1" />
      <TextField source="appid" />
    </Datagrid>
  </List>
);

export const MemShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="_id" />
      <TextField source="kbase" />
      <TextField source="uid" />
      <TextField source="krid" />
      <TextField source="level" />
      <TextField source="df" />
      <TextField source="nrt" />
      <TextField source="failed" />
      <TextField source="ts" label="ts" />
      <TextField source="ts1" label="ts1"/>
      <TextField source="appid" />
    </SimpleShowLayout>
  </Show>
);