import * as React from 'react';
import { Show, SimpleShowLayout, List, Datagrid, TextField,
  EditButton, Edit, SimpleForm, TextInput, 
  Create, 
  FunctionField} from 'react-admin';

export const AdminList = props => (
  <List {...props} sort={{ field: 'username', order: 'ASC' }}>
    <Datagrid rowClick="show">      
      <TextField label="用户名" source="username" />
      <TextField label="邮箱" source="email" />
      <TextField label="电话" source="mobile" />
      <EditButton />
    </Datagrid>
  </List>
);

export const AdminShow = (props) => {
  return (
    <Show title={<AdminTitle />} {...props}>
      <SimpleShowLayout>
          <TextField source="_id" />
          <TextField source="username" />
          <TextField source="email" />
          <TextField source="mobile" />          
          <FunctionField label="创建时间" render={(record) => {
            let d = new Date(record.add_time);
            return `${d.toLocaleString()}`;
          }}  />
      </SimpleShowLayout>
    </Show>
  );
}

const AdminTitle = ({record}) => {
  return <span>管理员 {record ? `${record.username}` : ''}</span>;
};

export const AdminEdit = props => {
  console.log(`props: ${JSON.stringify(props)}`);
  return (
    <Edit title={<AdminTitle />} {...props}>
      <SimpleForm>
        <TextInput source="_id" />
        <TextInput source="username" />
        <TextInput source="email" />
        <TextInput source="password" initialValue=""/>
      </SimpleForm>
    </Edit>
  );
};

export const AdminCreate = props => (
  <Create title={<AdminTitle />} {...props}>
      <SimpleForm redirect="list">    
        <TextInput source="username" />
        <TextInput source="email" />
        <TextInput source="mobile" />
        <TextInput source="password" initialValue=""/>
      </SimpleForm>
  </Create>
);
