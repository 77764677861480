export const AuthSource = ({ record, source }) => {
  let email = record["email"];
  let text = email;
  let oidcs = record["oidcs"];
  if (oidcs) {
    let weixin = oidcs["weixin"];
    if (weixin) {
      text = `微信: ${weixin["nickname"]}`;
    }

    let qq = oidcs["qq"];
    if (qq) {
      text = `QQ: ${qq["nickname"]} - ${qq["city"]} - ${qq["province"]}`;
    }

    let appleid = oidcs["appleid"];
    if (appleid) {
      text = `Apple: ${appleid["lastName"]} - ${appleid["firstName"]}`;
    }

    let line = oidcs["line"];
    if (line) {
      text = `Line: ${line["nickname"]}`;
    }
  }

  return <span>{text}</span>;
};
