import * as React from 'react';
import { List, Datagrid, TextField, Show, ReferenceField,
  SimpleShowLayout, Filter, TextInput, EditButton,
  Edit, SimpleForm, NumberInput, DateInput } from 'react-admin';

const KrFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search Question" source="question" alwaysOn />      
      <TextInput label="answer" source="answer" />      
      <TextInput label="kbase" source="kbase" />  
      <TextInput label="_id" source="id" />
      <TextInput label="uid" source="uid" />
  </Filter>
);

export const KrList = props => (
  <List filters={<KrFilter />} {...props}  sort={{ field: 'ts', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="kbase" />
      <ReferenceField label="用户" source="uid" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="question" />
      <TextField source="answer" />
      <TextField source="samples" />
      <TextField source="ts" />
      <EditButton />
    </Datagrid>
  </List>
);

export const KrShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="_id" />
      <TextField source="kbase" />
      <TextField source="uid" />
      <TextField source="question"/>
      <TextField source="answer" />
      <TextField source="samples" />
      <TextField label="ts" source="ts" />
      <TextField label="ts1" source="ts1" />
      <TextField source="appid" />
    </SimpleShowLayout>
  </Show>
);

export const KrEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextField source="uid" />
      <NumberInput source="kbase" />
      <TextInput source="question" multiline />
      <TextInput source="answer" multiline/>
      <TextInput source="samples" multiline/>
      <DateInput source="ts" />
      <DateInput source="ts1" />
      <NumberInput source="appid" />                    
    </SimpleForm>
  </Edit>
);