import * as React from 'react';
import { Admin, Resource }  from 'react-admin';

import { KrList, KrShow, KrEdit } from './resources/krs';
import { MemList, MemShow } from './resources/mems';
import { UserList, UserShow, UserEdit } from './resources/users';
import { AdminList, AdminShow, AdminCreate, AdminEdit } from './resources/admins';
import { AllMemstatList, AllmemstatShow } from './resources/allmemstats';
import { DailyMemstatList, DailyMemstatShow } from './resources/dailymemstats';
import { DailyNewUserList, WeeklyNewUserList, MonthlyNewUserList } from './resources/newusers';
import { ActiveUserList } from './resources/activeusers';
import { BookList, BookCreate, BookEdit, BookShow } from './resources/books';
import { IapReceiptList } from './resources/iapreceipts';
import { IapLogList } from './resources/iaplogs';

import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';

import Dashboard from './widgets/dashboard';
import Layout from './widgets/Layout';

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}
    dashboard={Dashboard} 
    layout={Layout}> 
    <Resource name="admins" 
      options={{ label: '管理员' }}
      list={AdminList} 
      show={AdminShow} 
      edit={AdminEdit} 
      create={AdminCreate}/>
    <Resource name="users" 
      options={{ label: '用户' }}
      list={UserList} show={UserShow} edit={UserEdit}/>
    <Resource name="receipts" 
      options={{ label: '购买凭据' }}
      list={IapReceiptList} />
    <Resource name="iaplogs" 
      options={{ label: '购买日志' }}
      list={IapLogList}/>
    <Resource name="krs" 
      options={{ label: 'Krs' }}
      list={KrList} show={KrShow} edit={KrEdit} />
    <Resource name="mems" 
      options={{ label: 'Mems' }}
      list={MemList} show={MemShow} />
    <Resource name="allmemstats" list={AllMemstatList} show={AllmemstatShow} />
    <Resource name="dailymemstats" list={DailyMemstatList} show={DailyMemstatShow} />
    <Resource name="monthlymemstats" list={DailyMemstatList} show={DailyMemstatShow} />    

    <Resource name="dailynewusers" list={DailyNewUserList} />
    <Resource name="weeklynewusers" list={WeeklyNewUserList} />
    <Resource name="monthlynewusers" list={MonthlyNewUserList} />
    <Resource name="dailyactiveusers" list={ActiveUserList} />    
    <Resource name="monthlyactiveusers" list={ActiveUserList} />
    <Resource name="books" list={BookList} show={BookShow} create={BookCreate} edit={BookEdit}/>
  </Admin>
);

export default App;
