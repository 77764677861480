import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { apiUrl } from '../providers/urls';

const Dashboard = () => (
  <Card>
      <Title title="lilyreading后台管理" />
      <CardContent>API 地址：{apiUrl}</CardContent>
  </Card>
);

export default Dashboard;
