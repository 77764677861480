import * as React from 'react';
import { List, Datagrid, NumberField, TextField } from 'react-admin';

export const DailyNewUserList = props => (
  <List {...props} bulkActionButtons={false} sort={{ field: '_id', order: 'DESC' }}>
      <Datagrid>
          <TextField label="日期" source="_id" />
          <NumberField source="total" />
          <NumberField source="android" />
          <NumberField source="ios" />          
      </Datagrid>
  </List>
);

export const WeeklyNewUserList = props => (
  <List {...props} bulkActionButtons={false} sort={{ field: '_id', order: 'DESC' }}>
      <Datagrid>
          <TextField label="星期" source="_id" />
          <NumberField source="total" />
          <NumberField source="android" />
          <NumberField source="ios" />          
      </Datagrid>
  </List>
);

export const MonthlyNewUserList = props => (
  <List {...props} bulkActionButtons={false} sort={{ field: '_id', order: 'DESC' }}>
      <Datagrid>
          <TextField label="月份" source="_id" />
          <NumberField source="total" />
          <NumberField source="android" />
          <NumberField source="ios" />          
      </Datagrid>
  </List>
);