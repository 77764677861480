import * as React from 'react';
import { List, Datagrid, Filter, TextInput, DateField, TextField, EditButton } from 'react-admin';
import { AuthSource } from '../widgets/AuthSource';

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search Email" source="email" alwaysOn />
        <TextInput label="_id" source="id" />
    </Filter>
  );
  
export const IapReceiptList = props => (
    <List filters={<UserFilter />} {...props} sort={{ field: 'receipt.purchaseDate', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField label="UserId" source="_id" />
      <AuthSource label="注册方式" source="_id" />
      <TextField source="username" />
      <DateField showTime="true" label="修改时间" source="receipt.ts" />
      <DateField showTime="true" label="expiresDate" source="receipt.expiresDate" />
      <DateField showTime="true" label="purchaseDate" source="receipt.purchaseDate" />
      <TextField label="orderId" source="receipt.orderId" />
      <TextField label="iapId" source="receipt.iapId" />
      <TextField label="reg" source="exts.app_platform" />
      <TextField label="login" source="exts.login_app_platform" />
    </Datagrid>
  </List>
);

