import axios from 'axios';
import { apiUrl } from './urls';

const AuthProvider = {
  login: async ({ username, password }) => {
    console.log('authProvider: login');
    const url = `${apiUrl}/login`;
    const response = await axios.post(url, 
      {
        username,
        password
      });

    console.log(`authProvider login response: ${JSON.stringify(response)}`);
    if(response.data.ok === 1) {
      localStorage.setItem('username', username);
      localStorage.setItem('jwt', response.data.jwt);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  // called when the user clicks on the logout button
  logout: async () => {
    console.log('authProvider: logout');
    localStorage.removeItem('username');
    localStorage.removeItem('jwt');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    console.log('authProvider: checkError', status);
    //if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject({ redirectTo: '/login' });
    //}
    //return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    let username = localStorage.getItem('username');
    console.log(`authProvider checkAuth: username - ${username}`);    
    return username
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    try {
        return Promise.resolve({ fullName: localStorage.getItem('username') });
    } catch (error) {
        return Promise.reject(error);
    }
  },
  authHeader: () => {
    const jwt = localStorage.getItem('jwt');
    if(jwt) {
      return { Authorization: `Bearer ${jwt}`};
    } else {
      return {};
    }
  },
};

export default AuthProvider;
