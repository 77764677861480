import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import UserIcon from '@material-ui/icons/People';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ListIcon from '@material-ui/icons/List';
import { useMediaQuery, Box } from '@material-ui/core';
import {
    DashboardMenuItem,
    MenuItemLink,    
} from 'react-admin';

import SubMenu from './SubMenu';

//type MenuName = 'menuStatistic' | 'menuUsers' | 'menuLearnRecords';

const Menu = ({ onMenuClick, logout, dense = false }) => {
    const [state, setState] = useState({
      menuStatistic: true,
      menuUsers: true,
      menuLearnRecords: true,
    });
    
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box mt={1}>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <SubMenu
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                sidebarIsOpen={open}
                name="用户"
                icon={<UserIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/admins`}
                    primaryText="管理员"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/users`}
                    primaryText="普通用户"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/receipts`}
                    primaryText="购买凭据"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/iaplogs`}
                    primaryText="购买日志"
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuStatistic')}
                isOpen={state.menuStatistic}
                sidebarIsOpen={open}
                name="用户统计"
                icon={<EqualizerIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/dailynewusers`}
                    primaryText='新注册用户（每天）'
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/weeklynewusers`}
                    primaryText='新注册用户（每周）'
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/monthlynewusers`}
                    primaryText='新注册用户（每月）'
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/dailyactiveusers`}
                    primaryText='活跃用户（每天）'
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/monthlyactiveusers`}
                    primaryText='活跃用户（每月）'
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuLearnRecords')}
                isOpen={state.menuLearnRecords}
                sidebarIsOpen={open}
                name="学习记录"     
                icon={<ListIcon />}           
                dense={dense}
            >
                <MenuItemLink
                    to={`/krs`}
                    primaryText="知识条目"                    
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/mems`}
                    primaryText="Mems"                    
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/allmemstats`}
                    primaryText="allmemstats"                    
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/dailymemstats`}
                    primaryText="dailymemstats"                    
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/monthlymemstats`}
                    primaryText="monthlymemstats"                    
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>    
            <MenuItemLink
                to={`/books`}
                primaryText='课本'
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />        
            {isXSmall && logout}
        </Box>
    );
};

export default Menu;
