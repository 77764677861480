import * as React from 'react';
import { List, Datagrid, NumberField, TextField } from 'react-admin';

export const ActiveUserList = props => (
  <List {...props} bulkActionButtons={false} sort={{ field: '_id', order: 'DESC' }}>
    <Datagrid>
      <TextField label="日期" source="_id" />
      <NumberField source="total" />        
    </Datagrid>
  </List>
);