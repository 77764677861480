import * as React from 'react';
import { List, Datagrid, Filter, TextInput, DateField, TextField } from 'react-admin';

const LogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="uid" source="uid" />
    </Filter>
  );
  
export const IapLogList = props => (
    <List filters={<LogFilter />} {...props} sort={{ field: 'ts', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="uid" />
      <DateField label="修改时间" source="ts" />
      <TextField label="sku" source="sku" />
      <TextField label="transactionDate" source="transactionDate" />
      <TextField label="orderId" source="orderId" />
      <TextField label="msg" source="msg" />
    </Datagrid>
  </List>
);

