import * as React from 'react';
import { List, Datagrid, TextField, ReferenceField, Filter, TextInput,
  Show, SimpleShowLayout, DateField, NumberField } from 'react-admin';

const DailyMemstatFilter = props => (
  <Filter {...props}>
    <TextInput label="Search uid" source="uid" alwaysOn />
    <TextInput source="id" />
  </Filter>
);
  
export const DailyMemstatList = props => (
  <List filters={<DailyMemstatFilter />} {...props} sort={{ field: 'ts0', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <ReferenceField label="User" source="uid" reference="users">
        <TextField  source="email" />
      </ReferenceField>      
      <NumberField label="复习时间 (秒)" source="time" />
      <NumberField label="积分" source="score" />
      <NumberField label="添加个数" source="new" />
      <NumberField label="复习个数" source="rvw" />
      <NumberField label="完成个数" source="fin" />
      <DateField label="时间点" source="ts0" />       
    </Datagrid>
  </List>
);

export const DailyMemstatShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="_id" />
      <ReferenceField source="uid" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <NumberField source="time" />
      <NumberField source="score" />
      <NumberField source="new" />
      <NumberField source="rvw" />
      <NumberField source="fin" />
      <DateField source="ts" />
      <NumberField source="appid" />          
  </SimpleShowLayout>
  </Show>
);