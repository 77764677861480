import * as React from 'react';
import { List, Datagrid, TextField, Show, SimpleShowLayout, 
  Filter, TextInput, BooleanInput, DateField, EditButton, DateInput,
  SimpleForm, Edit, BooleanField} from 'react-admin';
import { AvatarImage } from '../widgets/AvatarImage';
import { AuthSource } from '../widgets/AuthSource';

const UserFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search Email" source="email" alwaysOn />
      <TextInput label="_id" source="id" />
      <TextInput label="Username" source="username" />
      <TextInput label="appId" source='appid' />
      <TextInput label="langCode" source='lang' />
  </Filter>
);

export const UserList = props => (
  <List filters={<UserFilter />} {...props} sort={{ field: 'ts', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <AuthSource label="注册方式" source="_id" />
      <TextField source="username" />
      <DateField label="修改时间" source="ts" />
      <TextField label="app" source="appid" />
      <TextField label="lang" source="lang" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="_id" />
      <AvatarImage label="头像" source="_id" />
      <TextField source="email" />
      <TextField source="username" />
      <TextField label="修改时间" source="ts" />
      <TextField label="注册产品" source="exts.app_key" />
      <TextField label="注册平台" source="exts.app_platform" />
      <TextField label="注册产品版本" source="exts.app_version" />
      <TextField label="最近登录产品" source="exts.login_app_platform" />
      <TextField label="最近登录平台" source="exts.login_app_key" />
      <TextField label="最近登录产品版本" source="exts.login_app_version" />
      <BooleanField label="是否显示全屏广告" source="infos.admobi" />
      <BooleanField label="是否显示Banner广告" source="infos.admobb" />
      <DateField label="VIP有效期" source="vip.expire" />
    </SimpleShowLayout>
  </Show>
);

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm >
      <TextInput source="_id" />
      <TextInput source="username" />
      <TextInput source="email" />
      <TextInput source="password" />
      <BooleanInput source="infos.admobi" initialValue={false}/>
      <BooleanInput source="infos.admobb" initialValue={true}/>   
      <DateInput source="vip.expire" />
    </SimpleForm>
  </Edit>
);