import * as React from 'react';
import { Show, SimpleShowLayout, List, Datagrid, TextField,
  EditButton, DeleteButton,
  Edit, SimpleForm, TextInput, FileInput,
  Create, DateField, FileField, FunctionField, UrlField
  } from 'react-admin';

export const BookList = props => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="show">      
      <TextField source="id" />
      <TextField label='名称' source='name' />
      <DateField label="修改时间" source="ts" />
      <EditButton />
      <DeleteButton />   
    </Datagrid>
  </List>
);

const BookTitle = ({record}) => {
  return <span>课本： {record ? `${record.name}` : ''}</span>;
};

export const BookShow = (props) => {
  return (
    <Show title={<BookTitle />} {...props}>
      <SimpleShowLayout>
          <TextField source="_id" />
          <TextField source="name" label='名称' />
          <TextField source="author" label='作者' />
          <TextField source="desc" label='描述' />   
          <UrlField source="file" label='文件' /> 
          <FunctionField label="修改时间" render={(record) => {
            let d = new Date(record.ts);
            return `${d.toLocaleString()}`;
          }}  />
      </SimpleShowLayout>
    </Show>
  );
}

export const BookCreate = props => (
  <Create title="新建" {...props}>
      <SimpleForm redirect="list">    
        <TextInput source="name" label="书名" />
        <TextInput source="author" label="作者" />
        <TextInput source="desc" label="描述" />
        <FileInput source="bookfile" label="文件" >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
  </Create>
);

export const BookEdit = props => {
  return (
    <Edit title={<BookTitle />} {...props}>
      <SimpleForm>
        <TextField source="id" />
        <TextInput source="name" />
        <TextInput source="author" />
        <TextInput source="desc" />
        <FileInput source="bookfile" helperText="最大200M" accept=".zip" maxSize={200000000} >
          <FileField source="src" title="title" />
        </FileInput>          
      </SimpleForm>
    </Edit>
  );
};



    
